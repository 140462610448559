import React from 'react'
import './index.css'
import { Header } from 'components'

export const Page = ({ className = '', headerTheme, onContentScroll, ...props }) =>
<>
  <Header className="mobile:d-none2" theme={headerTheme} />
  <main className={`page ${className}`} onScroll={onContentScroll} {...props} />
</>

Page.Cover = ({ className = '', ...props}) =>
  <div className={`page__cover ${className}`} {...props} />
