import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import {
  createBrowserRouter,
  RouterProvider,
  redirect
} from 'react-router-dom'
import {
  Home,
  OskarsSylwanProfile
} from './pages'

const router = createBrowserRouter([
  {
    path: '/',
    // loader: () => redirect('/en'),
    element: <Home />
  },
  {
    path: '/profile',
    element: <OskarsSylwanProfile />
  },
])

export const App = () =>
  <RouterProvider router={router} />
