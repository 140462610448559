import React, { useRef, useState } from 'react'
import './index.css'
import {
  ButtonAffirmative,
  Paragraph,
  Ul,
  Header,
  Title,
  Page
} from 'components'
import {
  DirectoryIcon,
  Store,
  TextSnippet,
  Equalizer,
  Cached,
  Next,
  PresentToAll,
  AddToQueue,
  Dasboard
} from 'components/icons'

const isSafariBrowser = () => {
  // https://gist.github.com/michancio/10105690
  const isChrome = navigator.userAgent.indexOf('Chrome') > -1
  const isSafari = navigator.userAgent.indexOf('Safari') > -1

    if (isSafari){
        if (isChrome)  // Chrome seems to have both Chrome and Safari userAgents
            return false;
        else
            return true;
    }
    return false;
}

const useClipboardCopy = () => {
  if (isSafariBrowser()) return [false, () => {}]
  return [
    true,
    textToCopy => navigator.permissions.query({name: 'clipboard-write'})
      .then(result => {
        if (result.state === 'granted' || result.state === 'prompt') {
          navigator.clipboard.writeText(textToCopy)
        }
      })
    ]
}

export const Home = props => {
  const contactSectionRef = useRef(null)
  const [canCopy, copy] = useClipboardCopy()
  const [headerTheme, setHeaderTheme] = useState('light')

  const onContentScroll = event => {
    const contactRectangle = contactSectionRef.current.getBoundingClientRect()
    if (contactRectangle.top <= 77 && headerTheme == 'light') setHeaderTheme('dark')
    else if (contactRectangle.top > 76 && headerTheme == 'dark') setHeaderTheme('light')
  }

  const copyEmailToClipboard = () => {
    copy('oskars.sylwan@ossy.se')
  }

  return (
    <Page onScroll={onContentScroll} headerTheme={headerTheme}>

      <div id="home" className="home d-flex flex-column align-start justify-center">
        <Title className="stack-s" style={{ lineHeight: '1' }}>
          I build dashboards
        </Title>
        <div>
          <Paragraph style={{ maxWidth: '405px' }}>
            I’m a Frontend Developer that specialises in building dashboards
            and administrative applications in modern JavaScript frameworks.
          </Paragraph>
          <ButtonAffirmative as="a" href="#contact" className="mobile:d-none d-none" style={{ display: 'none' }}>
            Contact Me
          </ButtonAffirmative>
        </div>
      </div>

      <div id="services" className="services">
        <div className="services__wrapper stack-xl">

          <div className="services__service">
            <AddToQueue className="services__service-icon mobile:d-none"/>
            <div>
              <Title level="2" style={{ lineHeight: '1' }}>Building something new?</Title>
              <div style={{ marginTop: '16px', flex: '0 0 100%'  }}>
                <Paragraph style={{ maxWidth: '400px' }}>
                  Besides my solid foundation as a Frontend developer
                  I also have experience in build APIs and writing infrastructure as code.
                  This means I would be a valuable team member as I can help throughout the application stack.
                </Paragraph>
              </div>
            </div>
          </div>

          <div className="services__service">
            <PresentToAll className="services__service-icon mobile:d-none" />
            <div>
              <Title level="2" tyle={{ lineHeight: '1' }}>Converting functionality?</Title>
              <div style={{ marginTop: '16px', flex: '0 0 100%'  }}>
                <Paragraph style={{ maxWidth: '400px' }}>
                  Upgrading your tech stack whithout disrupting daily operations is hard.
                  Having a person on your team that has done it before is a huge help.
                  I can be that person.
                </Paragraph>
              </div>
            </div>

          </div>

          <div className="services__service">
            <Dasboard className="services__service-icon mobile:d-none" />
            <div>
                <Title level="2" tyle={{ lineHeight: '1' }}>Need scalable UI components?</Title>
                <div style={{ marginTop: '16px', flex: '0 0 100%'  }}>
                  <Paragraph style={{ maxWidth: '400px' }}>
                    I understand that being flashy isn’t the priority when building application interfaces.
                    Rather, making sure the design and components can scale with the
                    application and writing well tested code is much more desirable.
                    That’s what I do.
                  </Paragraph>
                </div>
            </div>
          </div>

        </div>

        <div className="stack-m">
          <ButtonAffirmative as="a" href="#contact" className="inline-m mobile:d-none">
            Contact me and we can talk about it
          </ButtonAffirmative>
        </div>
      </div>


      <Page.Cover>
        <div id="contact" ref={contactSectionRef} className="contact d-flex justify-center align-center w-100" style={{ background: 'var(--highlight)' }}>

          <div>
            <span style={{ fontSize: '6vw', color: 'hsl(199, 80%, 95%)', fontFamily: 'var(--font-sans-serif)' }} className="text-shadow bold d-block">oskars.sylwan@ossy.se</span>
            <div className="text-center" style={{ marginTop: '48px' }}>
              { canCopy && (
                <ButtonAffirmative onClick={copyEmailToClipboard} theme="dark">
                  Copy email to clipboard
                </ButtonAffirmative>
              )}
            </div>
          </div>

        </div>
      </Page.Cover>


    </Page>
  )
}
