import React from 'react'
import './index.css'

const disabledStyle = {
  borderColor: 'hsl(0, 0%, 90%)',
  color: 'hsl(0, 0%, 85%)',
  cursor: 'not-allowed'
}

const Button = ({ type, className = '', ...props }) =>
  <button type={type || 'button'} className={`button ${className} `} {...props} />

export const ButtonLink = ({ className = '', disabled, href, target = '_blank', ...props}) =>
  href
    ? <a className={`button button--link ${className} ${disabled ? 'button--disabled' : ''}`} {...props} disabled={disabled} target={target} href={href}/>
    : <Button className={`button--link ${className} ${disabled ? 'button--disabled' : ''}`} {...props} disabled={disabled}/>

export const ButtonNeutral = ({ className = '', ...props}) =>
  <Button className={`button--neutral ${className} `} {...props} />

export const ButtonDisabled = props =>
  <Button style={disabledStyle} {...props} disabled/>

export const ButtonAffirmative = ({ className = '', ...props}) =>
  <Button className={`button--affirmative ${className} `} {...props}/>

export const ButtonDanger = ({ className = '', ...props}) =>
  <Button className={`button--neutral button--danger ${className} `} {...props}/>
