export * from './neutral'
import './index.css'

const Button = ({ theme = 'light', className = '', ...props }) => {
  const Element = props.as || 'button'
  return <Element {...props} className={`button button--${theme} ${className}`} />
}

export const ButtonAffirmative = ({ className = '', ...props }) =>
  <Button className={`button button--affirmative ${className}`} {...props}/>

export const ButtonLink = ({ className = '', ...props }) =>
  <Button className={`button button--link ${className}`} {...props}/>
