import React from 'react'
import './index.css'
import { withClasses, withStyles } from 'components/utility'

export const Title = ({
  level = 1,
  className = '',
  children,
  ...props
}) => {
  const Element = 'H' + level
  return (
    <Element className={`title title--${level} ${className}`} {...props}>
      {children}
    </Element>
  )
}

export const H1 = props =>
  <Title {...props} level={1}>
    {props.children}
  </Title>

export const P = props => {
  const Element = props.as || 'p'
  const classes = withClasses('font-sans-serif slim')(props.className)
  const styles = withStyles({ lineHeight: '1.6' })(props.styles)
  return <Element style={styles} className={classes}>{props.children}</Element>
}

export const Ul = props => {
  const classes = withClasses('font-sans-serif slim')(props.className)
  const styles = withStyles({ paddingLeft: '48px', lineHeight: '1.6' })(props.styles)
  return <ul style={styles} className={classes}>{props.children}</ul>
}
