import React from 'react'
import './index.css'
import { withClasses } from 'components/utility'

export const Logo = ({
  className = '',
  theme = 'light',
  ...props
}) =>
  <div className={`logo logo--${theme} ${className}`} {...props}>
    <span>OS</span>
  </div>
