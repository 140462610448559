import React from 'react'
import './index.css'

export const Paragraph = ({
  className = '',
  children,
  ...props
}) =>
  <p className={`paragraph ${className}`} {...props}>
    {children}
  </p>
