import React from 'react'
import './index.css'

export const Card = ({
  className = '',
  children,
  ...props
}) =>
  <div className={`card ${className}`} {...props}>
    {children}
  </div>
