import React from 'react'
import './index.css'

const ListItem = ({ className = '', ...props }) =>
  <li className={`list__item ${className}`}{...props}>{props.children}</li>

export const List = ({
  direction = 'block',
  className = '',
  ...props
}) =>
  <ul className={`list list--${direction} ${className}`} {...props}>
    { React.Children.map(props.children, child => <ListItem>{child}</ListItem>) }
  </ul>
