import React from 'react'
import './index.css'

export const Tag = ({
  onSelect,
  active,
  ...props
}) =>
  <li
    className={`tag ${!!onSelect ? 'tag--clickable' : ''} ${active ? 'tag--active' : ''}`}
    onClick={!!onSelect ? () => onSelect(props.children) : () => {} }
  >
    {props.children}
  </li>

export const Tags = ({
  tags = [],
  activeTags = [],
  className,
  onSelect,
  ...props
}) =>
  <ul className={`tags ${className}`} {...props}>
    {tags.map(tag => <Tag onSelect={onSelect} active={activeTags.includes(tag)} key={tag}>{tag}</Tag>)}
    {props.children}
  </ul>
