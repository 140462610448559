import React from 'react'
import { Website, GitHub } from 'components/icons'
import { Tags, Card } from 'components'
import { ButtonLink } from '../buttons'
import './index.css'

export const Experience = ({
  title,
  subTitle,
  date,
  description,
  website,
  github,
  tags = [],
  className = '',
  ...props
}) =>
  <Card className={`experience stack-l break-inside-avoid ${className}`} {...props}>
    <div className="d-flex justify-between align-center stack-s">
      <div>
        <h3 className="experience__title">{title}</h3>
        <div className="experience__sub-title bold">{subTitle}</div>
      </div>
      <span className="experience__date">{date}</span>
    </div>
    <div className={`experience__description color-grey font-sans-serif ${(!!website || !!github) && 'stack-m'}`}>
      {!!description ? description : props.children}
    </div>
    <div className={`${(!!tags && !!tags.length) && 'stack-m'}`}>
      {
        !!website && (
          <a className="experience__link inline-xl" href={website} target="_blank">
            <Website className="experience__link-icon inline-m"/>Visit website
          </a>
        )
      }
      {
        !!github && (
          <a className="experience__link" href={github} target="_blank">
            <GitHub className="experience__link-icon" style={{ with: '22px', height: '22px' }}/>GitHub
          </a>
        )
      }
    </div>
    {
      !!tags && !!tags.length && <Tags tags={tags}/>
    }
  </Card>
