import React, { useState, useEffect, useRef } from 'react'
import ProfileImage from './profile-oskarssylwan.jpg'
import { Experience } from './experience'
import { Title } from './title'
import { Card, Paragraph, Tags } from 'components'
import { useQueryParams } from 'hooks'
import { useParams } from 'react-router-dom'
import './new.css'
import './index.css'
import {
  pipe,
  find,
  pathOr,
  map,
  trim,
  split,
  when,
  always,
  lensPath,
  filter,
  prop,
  propOr,
  sort,
  propEq,
  groupBy,
  converge,
  pair,
  head,
  append,
  without,
  evolve,
  propSatisfies,
  toLower,
  over,
  ifElse
} from 'ramda'
import { ButtonLink, ButtonAffirmative, ButtonNeutral } from './buttons'
import moment from 'moment'
import { cmsUrl } from 'config'
import { Call, Email, Website } from 'components/icons'

const experienceByDate = (a, b) => {
  const getDate = pipe(pathOr('1900', ['data', 'Date']), split(' - '), head)
  const dateA = moment(getDate(a), ['MMM YYYY', 'YYYY']).format('YYYYMM')
  const dateB =  moment(getDate(b), ['MMM YYYY', 'YYYY']).format('YYYYMM')
  return dateB - dateA
}

const transformToExperience = document => ({
  ...document.data,
  Title: document.name,
  Tags: document.data.Tags ? map(trim,  document.data.Tags.split(',')) : undefined,
  key: document.data.Date + document.name,
})

const getExperiences = pipe(
  filter(propEq('templateName', 'Experience')),
  sort(experienceByDate),
  map(transformToExperience),
  groupBy(prop('Type of experience'))
)

const transformTags = pipe(
  pathOr('', ['data', 'Tags']),
  split(','),
  map(trim)
)

const getIntroduction = pipe(
  when(x => !x, always([])),
  find(document => document.templateName === 'Introduction'),
  ifElse(x => x,
    prop('data'),
    () => ({
      Introduction: '',
      Tags: '',
      Mobile: '',
      Email: '',
      Website: '',
      'Consultant Name': '',
      Role: ''
  })),
  over(lensPath(['Introduction']), split('\n\n')),
  over(lensPath(['Tags']), split(',')),
)

const titles = {
  sv: {
    tags: 'Erfarenhet med',
    work: 'Arbete',
    projects: 'Projekt',
    education: 'Utbildning',
    other: 'Övrigt'
  },
  en: {
    tags: 'Experience with',
    work: 'Work',
    projects: 'Projects',
    education: 'Education',
    other: 'Other'
  }
}

export const OskarsSylwanProfile = () => {
  const { language = 'en' } = useQueryParams()
  const [loading, setLoading] = useState(true)
  const [introduction, setIntroduction] = useState({})
  const [activeTags, setActiveTags] = useState([])
  const [experiences, setExperiences] = useState({})
  const [work, setWork] = useState([])
  const [education, setEducation] = useState([])
  const [projects, setProjects] = useState([])
  const [other, setOther] = useState([])
  const [activeExperienceType, setActiveExperienceType] = useState()
  const kronofogdenRef = useRef(null)

  const setCategoryExperiences = experiences => {
    setWork(experiences['Work'] || [])
    setEducation(experiences['Education'] || [])
    setProjects(experiences['Project'] || [])
    setOther(experiences['Other'] || [])
  }

  useEffect(() => {
    fetch(`${cmsUrl}/profiles/oskarssylwan/${language}/`)
      .then(response => response.json())
      .then(converge(pair, [getIntroduction, getExperiences]))
      .then(([introduction, experiences]) => {
        setIntroduction(introduction)
        setExperiences(experiences)
      })
      .then(() => { setLoading(false) })
  }, [language])

  useEffect(() => {
    if (!kronofogdenRef.current) return
    kronofogdenRef.current.innerHTML = pipe(
      find(experience => experience.Title === 'Kronofogden'),
      prop('Description')
    )(work)
  }, [work, kronofogdenRef])

  useEffect(() => {

    const hasActiveTag = propSatisfies(
      documentTags => (documentTags || []).map(toLower).some(tag => activeTags.map(toLower).includes(tag)),
      'Tags'
    )

    !activeTags.length
      ? setCategoryExperiences(experiences)
      : setCategoryExperiences(evolve({
      Work: filter(hasActiveTag),
      Education: filter(hasActiveTag),
      Project: filter(hasActiveTag),
      Other: filter(hasActiveTag)
    }, experiences))

  }, [activeTags, experiences])

  const isExperienceTypeActive = type =>
    !activeExperienceType ? true : activeExperienceType === type

  const toggleActiveExperienceType = type => () =>
    setActiveExperienceType(type === activeExperienceType ? '' : type)

  const toggleActiveTag = tag => {
    setActiveTags(activeTags.includes(tag) ? without([tag], activeTags) : append(tag, activeTags))
  }

  const print = () => {
    window.print()
  }

  const Experiences = ({ experiences }) => experiences.map(experience =>
    <Experience
      key={experience.key}
      title={experience.Title}
      subTitle={experience['Sub Title']}
      date={experience.Date}
      website={experience.Website}
      github={experience.GitHub}
      tags={experience.Tags}
    >
      {
        experience.Title !== 'Kronofogden'
          ? <Paragraph>{experience.Description}</Paragraph>
          : <div className="kronofogden--hack" ref={kronofogdenRef} />
      }
    </Experience>
  )

  return (
    <div className={`profile ${!!activeExperienceType && 'profile--center'} ${ loading && 'profile--loading'}`}>

      <Card className="profile__sidebar">

      <div className="profile__intro">
        <div className="profile_image stack-l">
          <img src={ProfileImage} alt="Oskars Sylwan"/>
        </div>

        <div className="profile__intro-text">

          <div className="profile__intro-name">
            <div>
              <h1 className="font-sans-serif text-center stack-m">Oskars Sylwan</h1>
              <div className="font-sans-serif text-center stack-xl color-grey">{introduction.Role}</div>
            </div>
          </div>

          <div className="stack-xl">

            <div className="d-flex align-center color-grey font-sans-serif stack-m" style={{ fontSize: '14px', }}>
              <Email className="fill-light-grey inline-l" style={{ width: '20px', height: '20px' }}/>
              {introduction.Email}
            </div>

            <div className="d-flex align-center color-grey font-sans-serif stack-m" style={{ fontSize: '14px', }}>
              <Call className="fill-light-grey inline-l" style={{ width: '20px', height: '20px' }}/>
              {introduction.Mobile}
            </div>


{
  // <div className="d-flex align-center color-grey font-sans-serif stack-m" style={{ fontSize: '14px', }}>
  //             <Website className="fill-light-grey inline-l" style={{ width: '20px', height: '20px' }}/>
  //             <a
  //               href={introduction.Website}
  //               target="_empty"
  //               className="link color-grey font-sans-serif"
  //               >{(introduction.Website || '').replace('https://', '')}
  //             </a>
  //           </div>
          }

          </div>

        </div>

      </div>

        <div>
          {
            propOr([], 'Introduction', introduction).map(paragraph => (
              <Paragraph key={paragraph} className="color-grey stack-m">
                {paragraph}
              </Paragraph>
            ))
          }
        </div>



        <div>
          <h2 className="font-sans-serif title">{titles[language].tags}</h2>
          <Tags tags={propOr([], 'Tags', introduction)} activeTags={activeTags} onSelect={toggleActiveTag}/>
        </div>

      </Card>

      <div className="profile__actions d-flex justify-between">

        <Card className="desktop:d-none w-100 font-sans-serif" style={{ fontWeight: 'bold', color: 'hsl(0, 0%, 30%)'}}>
          <div className="text-center">oskars.sylwan@gmail.com</div>
        </Card>

        <div className="mobile:d-none">
          <ButtonLink onClick={toggleActiveExperienceType('Work')} className={`inline-s ${activeExperienceType === 'Work' && 'active' }`}>Work</ButtonLink>
          <ButtonLink onClick={toggleActiveExperienceType('Project')} className={`inline-s ${activeExperienceType === 'Project' && 'active' }`}>Projects</ButtonLink>
          <ButtonLink onClick={toggleActiveExperienceType('Education')} className={`inline-s ${activeExperienceType === 'Education' && 'active' }`}>Education</ButtonLink>
          <ButtonLink onClick={toggleActiveExperienceType('Other')} className={`inline-s ${activeExperienceType === 'Other' && 'active' }`}>Other</ButtonLink>
        </div>
        <div className="mobile:d-none">
          <ButtonAffirmative onClick={print}>Download</ButtonAffirmative>
        </div>
      </div>

      <div className="profile__content-wrapper">
        <div className="profile__content">
          <div>
            {
              isExperienceTypeActive('Work') && (<>
                { !!work.length && <Title title={titles[language].work}/> }
                <Experiences experiences={work} />
              </>)
            }
          </div>

          <div>
            {
              isExperienceTypeActive('Project') && (<>
                { !!projects.length && <Title title={titles[language].projects} /> }
                <Experiences experiences={projects} />
              </>)
            }
          </div>

          <div>
            {
              isExperienceTypeActive('Education') && (<>
                { !!education.length && <Title title={titles[language].education}/> }
                <Experiences experiences={education} />
              </>)
            }
            {
              isExperienceTypeActive('Other') && (<>
                { !!other.length && <Title title={titles[language].other} /> }
                <Experiences experiences={other} />
              </>)
            }
          </div>
        </div>
      </div>

  </div>
  )
}
