import React from 'react'
import './index.css'
import { Logo, ButtonAffirmative, ButtonLink, List } from 'components'

export const Header = ({
  theme = 'light',
  className = '',
  ...props
}) =>
  <header className={`header header--${theme} ${className}`} {...props}>
    <Logo className="header__logo mobile:d-none" theme={theme}/>
    <nav className="pointer-events-auto">
      <List direction="inline">
        <ButtonLink as="a" href="#contact" theme={theme}>Contact me</ButtonLink>
        <ButtonLink as="a" href="#services" theme={theme} className="inline-l">Services</ButtonLink>
        <ButtonAffirmative as="a" href="#contact" theme={theme} className="mobile:d-none">
          Contact Me
        </ButtonAffirmative>
      </List>
    </nav>
  </header>
